import React, { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DragElement from "../../components/DragElement/DragElement"
import FifteenEl from "../../components/FifteenEl/FifteenEl"
import { replace, resetMovesCount, selectGameSize, selectMovesCount, setDraggable, setGameSize } from "../../store/fifteenSlise"
import DragZone from "../DragZone/DragZone"
import "./FifteensContainer.css"
import { selectFifteen } from "../../store/fifteenSlise"
import { Button, FormControlLabel, Modal, Radio, RadioGroup, Switch, Typography } from "@mui/material"
import isEqual from 'lodash/isEqual';
import { Box } from "@mui/system"
import { modalStyle } from "./styles"
import draggingHandler from "../../utils/fifteen/draggingHandler"

const FifteensContainer = () => {
    const [widthOrHeight, setWidthOrHeight] = useState(0)
    const [emptyElement, setEmptyElement] = useState(0)
    const [wonModalOpen, setWonModalOpen] = useState(false)
    const [gameMatrix, setGameMatrix] = useState([])
    const [styles, setStyles] = useState({
        width: "0px",
        height: "0px",
        border: "3px solid #292929",
        borderRadius: "8px",
        display: "grid",
        gridTemplateRows: "1fr 1fr 1fr 1fr",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        backgroundColor: "#cecece"
    })
    const dispatch = useDispatch()
    const elements = useSelector(selectFifteen)
    const gameSize = useSelector(selectGameSize)
    const movesCount = useSelector(selectMovesCount)

    useEffect(() => {
        window.addEventListener('resize', (e) => handleResize())
        handleResize()
        generateMatrix(gameSize)
        return () => {
            window.removeEventListener('resize', (e) => handleResize())
        }
    }, [])

    useEffect(() => {
        generateMatrix(gameSize)
        setStyles((prev) => {
            return {
                ...prev,
                gridTemplateRows: `repeat(${gameSize}, 1fr)`,
                gridTemplateColumns: `repeat(${gameSize}, 1fr)`,
            }
        })
    }, [gameSize])

    useEffect(() => {
        createWinMatrix()
    }, [gameMatrix])

    //set position on empty el
    useEffect(() => {
        if (!elements.length) return
        const empty = elements.find(item => item.id == gameSize * gameSize - 1)
        setEmptyElement(empty)
        checkWin()
    }, [elements])

    useEffect(() => {
        setStyles((prev) => ({
            ...prev,
            width: widthOrHeight - 60 + "px",
            height: widthOrHeight - 60 + "px",
        }))
    }, [widthOrHeight])

    //resize window

    const handleResize = () => {
        if (window.innerHeight > window.innerWidth) {
            setWidthOrHeight(window.innerWidth)
        } else {
            setWidthOrHeight(window.innerHeight)
        }
    }

    // generate different matrix 

    const generateMatrix = (size) => {
        let matrixArr = []

        for (let i = 1; i <= size; i++) {
            for (let j = 1; j <= size; j++) {
                matrixArr.push([i, j])
            }
        }
        setGameMatrix(matrixArr);
    }

    //create playground
    const randomize = () => {
        let elementsCopy = [...elements]
        for (let i = 0; i < 1000; i++) {
            let validElements = []
            const emptyEl = elementsCopy.find(item => item.id == gameSize * gameSize - 1)
            elementsCopy.forEach(item => {
                if (checkDraggable(item, emptyEl)) validElements.push(item)
            })
            const movedEl = validElements[Math.floor(Math.random() * validElements.length)];

            elementsCopy = draggingHandler(movedEl, elementsCopy, emptyEl)
        }

        dispatch(replace(elementsCopy))
        handleCloseModal()
        dispatch(resetMovesCount())
    }

    //createWinMatrix

    const createWinMatrix = () => {
        const elementsArr = []

        gameMatrix.forEach((item, i) => {
            elementsArr.push({
                id: i,
                pos: item
            })
        })

        dispatch(replace(elementsArr))
    }

    // check draggable
    const checkDraggable = (item, emptyEl) => {
        if (!emptyEl) return;
        if (item.id == gameSize * gameSize - 1) {
            return false
        } else if (item.pos[0] == emptyEl.pos[0] && (item.pos[1] + 1 == emptyEl.pos[1] || item.pos[1] - 1 == emptyEl.pos[1])) {
            return true
        } else if (item.pos[1] == emptyEl.pos[1] && (item.pos[0] + 1 == emptyEl.pos[0] || item.pos[0] - 1 == emptyEl.pos[0])) {
            return true
        } else {
            return false
        }
    }

    const checkWin = () => {
        const winArr = []
        gameMatrix.forEach((item, i) => {
            winArr.push({
                id: i,
                pos: item
            })
        })

        if (isEqual(winArr, elements) && movesCount > 0) {
            setWonModalOpen(true)
        }

    }

    const handleCloseModal = () => {
        setWonModalOpen(false)
        dispatch(resetMovesCount())
    }

    return <>
        <div className="FifteensContainer__playGround">
            <div className="FifteensContainer__buttons">
                <Button variant="contained" onClick={randomize}>mix</Button>
                <Button variant="contained" onClick={createWinMatrix}>win</Button>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    row
                    defaultValue="4"
                    name="radio-buttons-group"
                >
                    <FormControlLabel value="3" control={<Radio onChange={() => { dispatch(setGameSize(3)) }} />} label="3х3" />
                    <FormControlLabel value="4" control={<Radio onChange={() => { dispatch(setGameSize(4)) }} />} label="4х4" />
                    <FormControlLabel value="5" control={<Radio onChange={() => { dispatch(setGameSize(5)) }} />} label="5х5" />
                </RadioGroup>
                <div>MOVES: <span className="FifteensContainer__moves">{movesCount}</span> </div>

            </div>

            <DragZone styles={styles}>
                {elements.map((item, i) =>
                    <DragElement
                        draggable={checkDraggable(item, emptyElement)} key={item.id} item={item} emptyEl={emptyElement}>
                        <FifteenEl value={item.id + 1} draggable={checkDraggable(item, emptyElement)} />
                    </DragElement>
                )}
            </DragZone>
        </div>
        <Modal
            open={wonModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Congratulations
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    You WON!!!
                </Typography>
                <div className="FifteenContainer__modal-btns">
                    <Button variant="contained" onClick={randomize}>retry</Button>
                    <Button variant="contained" onClick={handleCloseModal}>close</Button>
                </div>

            </Box>
        </Modal>
    </>
}

export default FifteensContainer