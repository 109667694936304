import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectGameSize } from "../../store/fifteenSlise"
import "./FifteenEl.css"

const FifteenEl = ({ value, draggable }) => {
    const [classes, setClasses] = useState("")
    const [styles, setStyles] = useState({
        fontSize: "60px"
    })

    const gameSize = useSelector(selectGameSize)

    useEffect(() => {
        switch (gameSize) {
            case 3:
                setStyles({ fontSize: "80px" })
                break
            case 4:
                setStyles({ fontSize: "60px" })
                break
            case 5:
                setStyles({ fontSize: "30px" })
                break
        }

    }, [gameSize])



    useEffect(() => {
        if (value == gameSize * gameSize) {
            setClasses("FifteenEl__container_empty")
        } else if (draggable) {
            setClasses("FifteenEl__container FifteenEl__container_drag")
        } else {
            setClasses("FifteenEl__container FifteenEl__container_no-drag")
        }
    }, [draggable, gameSize])

    return <>
        <div className={classes} style={styles}>
            {value == gameSize * gameSize ? "" : value}
        </div>

    </>
}

export default FifteenEl