import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
    canDrop,
    replace,
    selectCanDrop,
    selectDroppedEl,
    selectFifteen,
    selectGameSize,
    selectMovesCount,
    setDroppedEl,
    setMovesCount
} from "../../store/fifteenSlise";
import "./styles.css"
import checkDirection from "../../utils/fifteen/checkDirection";
import draggingHandler from "../../utils/fifteen/draggingHandler";

const elStyles = {
    margin: "3px",
}

const DragElement = ({ children, draggable, item, emptyEl }) => {
    const [styles, setStyles] = useState(elStyles)
    const [dragClass, setDragClass] = useState("")
    const elements = useSelector(selectFifteen)
    const canDropValue = useSelector(selectCanDrop)
    const droppedEl = useSelector(selectDroppedEl)
    const gameSize = useSelector(selectGameSize)
    const movesCount = useSelector(selectMovesCount)
    const dispatch = useDispatch()

    useEffect(() => {
        const positionStyles = {
            gridRow: item.pos[0],
            gridColumn: item.pos[1],
        }
        setStyles((prev) => ({ ...prev, ...positionStyles }))
    }, [elements])


    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (canDropValue) {
            const newArr = draggingHandler(droppedEl, elements, emptyEl)
            dispatch(replace(newArr))
            dispatch(setMovesCount())
        }
    }

    const handleTouch = (e) => {
        if (draggable) {
            setDragClass(checkDirection(item.pos, emptyEl.pos));
            setTimeout(() => {
                const newArr = draggingHandler(item, elements, emptyEl)
                dispatch(replace(newArr))
                setDragClass("no-transition")
                dispatch(setMovesCount())
            }, 300)
        }
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (item.id == gameSize * gameSize - 1) {
            dispatch(canDrop(true))
        }
    }
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (item.id == gameSize * gameSize - 1) {
            dispatch(canDrop(false))
        }

    }
    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (droppedEl.id != item.id || droppedEl.pos[0] != item.pos[0] || droppedEl.pos[1] != item.pos[1]) {
            dispatch(setDroppedEl(item))
        }

    }

    return <>
        <div style={styles}
            draggable={draggable}
            onDrop={e => handleDrop(e)}
            onDrag={e => handleDrag(e)}
            onDragEnter={e => handleDragEnter(e)}
            onDragLeave={e => handleDragLeave(e)}
            onDragOver={e => { e.preventDefault(); e.stopPropagation(); }}
            onClick={() => { handleTouch() }}
            className={dragClass}
            onTouchStart={(e) => handleTouch(e)}
        >
            {children}
        </div>

    </>
}

export default DragElement