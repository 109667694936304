import { createSlice } from '@reduxjs/toolkit';

export const fifteenSlice = createSlice({
    name: 'fifteen',
    initialState: {
        elements: [],
        canDrop: false,
        droppedEl: {
            id: null,
            pos: []
        },
        gameSize: 4,
        movesCount: 0
    },
    reducers: {
        replace: (state, data) => {
            state.elements = data.payload
        },
        canDrop: (state, data) => {
            state.canDrop = data.payload
        },
        setDroppedEl: (state, data) => {
            state.droppedEl = data.payload
        },
        setGameSize: (state, data) => {
            state.movesCount = 0
            state.gameSize = data.payload

        },
        setMovesCount: (state) => {
            state.movesCount = state.movesCount + 1
        },
        resetMovesCount: (state) => {
            state.movesCount = 0
        },
    }

});

export const { replace, canDrop, setDroppedEl, setGameSize, setMovesCount, resetMovesCount } = fifteenSlice.actions;
export const selectFifteen = state => state.fifteen.elements;
export const selectCanDrop = state => state.fifteen.canDrop;
export const selectDroppedEl = state => state.fifteen.droppedEl;
export const selectGameSize = state => state.fifteen.gameSize;
export const selectMovesCount = state => state.fifteen.movesCount
export default fifteenSlice.reducer;