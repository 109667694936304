import { createSlice } from '@reduxjs/toolkit';

const torFilms = []

export const torFilmsSlice = createSlice({
    name: 'torFilms',
    initialState: {
        torFilms: torFilms,
    },
    reducers: {
        updateFilms: (state, data) => data.payload
    }
});

export const { updateFilms } = torFilmsSlice.actions;
export const selectTorFilms = state => state.torFilms;
export default torFilmsSlice.reducer;