const checkDirection = (itemPos, emptyPos) => {
    let direction = "";
    if (itemPos[1] > emptyPos[1]) {
        direction = "drag-left"
    } else if (itemPos[1] < emptyPos[1]) {
        direction = "drag-right"
    } else if (itemPos[0] > emptyPos[0]) {
        direction = "drag-top"
    } else if (itemPos[0] < emptyPos[0]) {
        direction = "drag-bottom"
    }
    return direction
}

export default checkDirection