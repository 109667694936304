import { combineReducers, configureStore } from '@reduxjs/toolkit';
import goodsReducer from './goodsSlice';
import cartReducer from './cartSlice';
import runesReducer from './runeSlice';
import runeWordsReducer from './runewordsSlice';
import localStorageReducer from './storageSlice';
import torFilmsReducer from './torFilmsSlice';
import fifteenReducer from './fifteenSlise';


const rootReducer = {
    goods: goodsReducer,
    cart: cartReducer,
    runes: runesReducer,
    runeWords: runeWordsReducer,
    localStorage: localStorageReducer,
    torFilms: torFilmsReducer,
    fifteen: fifteenReducer
}
export default rootReducer
