import axios from "axios";
import { updateFilms } from "../store/torFilmsSlice";

const url = process.env.NODE_ENV == "development" ? "http://ditrim.ru" : ".";

class TorFilmService {
    static getList = (user) => {
        return async (dispatch) => {
            axios.post(`${url}/php/Tfilms.php`, {
                type: 'getListSite',
                user: user
            }).then(function (response) {
                dispatch(updateFilms(response.data));
            })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    static addChapter = (user, name) => {
        return async (dispatch) => {
            axios.post(`${url}/php/Tfilms.php`, {
                type: 'addChapter',
                name: name,
                user: user
            }).then(function (response) {
                dispatch(updateFilms(response.data));
            })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    static addFilm = (user, name, link, parentID) => {
        return async (dispatch) => {
            axios.post(`${url}/php/Tfilms.php`, {
                type: 'addFilm',
                user: user,
                name: name,
                link: link,
                parentID: parentID
            })
                .then(function (response) {
                    dispatch(updateFilms(response.data));
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    static removeFilm = (user, filmID) => {
        return async (dispatch) => {
            axios.post(`${url}/php/Tfilms.php`, {
                type: 'removeFilm',
                user: user,
                filmID: filmID
            })
                .then(function (response) {
                    dispatch(updateFilms(response.data));
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    static removeChapter = (user, filmID) => {
        return async (dispatch) => {
            axios.post(`${url}/php/Tfilms.php`, {
                type: 'removeChapter',
                user: user,
                filmID: filmID
            })
                .then(function (response) {
                    dispatch(updateFilms(response.data));
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    static editChapter = (user, filmID, name) => {
        return async (dispatch) => {
            axios.post(`${url}/php/Tfilms.php`, {
                type: 'editChapter',
                user: user,
                filmID: filmID,
                name: name
            })
                .then(function (response) {
                    dispatch(updateFilms(response.data));
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
}

export default TorFilmService