import { Button, Collapse, Container, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, TextField, Typography } from "@mui/material"
import { blue } from '@mui/material/colors';
import { useState } from "react";
import CreateChapterModal from "../../components/TorrentFilms/CreateChapterModal/CreateChapterModal";
import FolderIcon from '@mui/icons-material/Folder';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TheatersIcon from '@mui/icons-material/Theaters';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AddFilmModal from "../../components/TorrentFilms/AddFilmModal/AddFilmModal";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import TorFilmService from "../../services/TorFilmsService";
import { useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import "./TorrentFilms.styles.css"
import EditChapterModal from "../../components/TorrentFilms/EditChapterModal/EditChapterModal";

const style = {
    "backgroundColor": blue[50],
    "padding": "20px"
}

const divStyle = {
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
}
const TorrentFilms = () => {
    const [isCreateChapterOpen, setIsCreateChapterOpen] = useState(false)
    const [isAddFilmOpen, setIsAddFilmOpen] = useState(false)
    const [isEditChapterOpen, setIsEditChapterOpen] = useState(false)
    const [user, setUser] = useState("")
    const [openedChapterID, setOpenedChapterId] = useState(null)
    const torFilms = useSelector(store => store.torFilms)
    const [editedChapterName, setEditedChapterName] = useState("")
    const [editedChapterID, setEditedChapterID] = useState(null)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(TorFilmService.getList(user))
    }, [])

    const chapterOpen = (id) => {
        openedChapterID == id ? setOpenedChapterId(null) : setOpenedChapterId(id)
    }

    const removeHandler = (filmID) => {
        dispatch(TorFilmService.removeFilm(user, filmID))
    }

    const removeChapterHandler = (e, filmID) => {
        e.stopPropagation()
        dispatch(TorFilmService.removeChapter(user, filmID))
    }

    const editChapterHandler = (e, id, name) => {
        e.stopPropagation()
        setEditedChapterName(name);
        setEditedChapterID(id);
        setIsEditChapterOpen(true)

    }

    return <>
        <Container
            maxWidth="lg"
            style={style}
        >
            <div style={divStyle}>
                <Typography id="catalog-title" variant="h2" component="h2">
                    Каталог
                </Typography>
                <TextField
                    id="chapter-name"
                    label="Пользователь"
                    variant="standard"
                    value={user}
                    onChange={(e) => { setUser(e.target.value) }} />
                <Button variant="contained" onClick={() => { setIsCreateChapterOpen(true) }}>Добавить раздел</Button>

                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Каталог
                        </ListSubheader>
                    }
                >
                    {torFilms.length ? torFilms.map(item => {


                        if (!item.parentID) {
                            const chapters = torFilms.filter(film => film.parentID && film.parentID == item.filmID) ?? []
                            return (
                                <div key={item.filmID}>
                                    <ListItemButton onClick={() => { chapterOpen(item.filmID) }} className="torrentFilms__edit-remove">
                                        <ListItemIcon>
                                            <FolderIcon />

                                        </ListItemIcon>
                                        <ListItemText primary={item.name} />
                                        {item.filmID == openedChapterID ?
                                            <ExpandLessIcon />
                                            : <ExpandMoreIcon />}
                                        <div className="torrentFilms__edit-container">
                                            <EditIcon
                                                className="torrentFilms__edit-button"
                                                onClick={(e) => { editChapterHandler(e, item.filmID, item.name) }} />
                                            <DeleteIcon
                                                className="torrentFilms__edit-button"
                                                onClick={(e) => { removeChapterHandler(e, item.filmID) }} />
                                        </div>
                                    </ListItemButton>
                                    <Collapse in={item.filmID == openedChapterID} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {chapters.map(item => (
                                                <ListItemButton sx={{ pl: 4 }} key={item.filmID} >
                                                    <ListItemIcon >
                                                        <TheatersIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={item.name} onClick={() => { window.location.href = item.link }} />
                                                    <DeleteIcon onClick={() => { removeHandler(item.filmID) }} />
                                                </ListItemButton>
                                            ))}
                                            <ListItemButton sx={{ pl: 4 }} onClick={() => { setIsAddFilmOpen(true) }}>
                                                <ListItemIcon>
                                                    <AddIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Добавить фильм" />
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                </div>
                            )
                        }
                    }) : null}
                </List>
            </div>

        </Container>
        <CreateChapterModal
            isOpen={isCreateChapterOpen}
            onClose={() => { setIsCreateChapterOpen(false) }}
            user={user}
        />
        <AddFilmModal
            isOpen={isAddFilmOpen}
            onClose={() => { setIsAddFilmOpen(false) }}
            user={user}
            parent={openedChapterID}
        />
        <EditChapterModal
            isOpen={isEditChapterOpen}
            onClose={() => { setIsEditChapterOpen(false) }}
            user={user}
            filmID={editedChapterID}
            name={editedChapterName}
        />

    </>
}

export default TorrentFilms