const draggingHandler = (droppedEl, elements, emptyEl) => {
    let tempEl = [...elements];
    tempEl[droppedEl.id] = {
        id: droppedEl.id,
        pos: emptyEl.pos
    }
    tempEl[emptyEl.id] = {
        id: emptyEl.id,
        pos: droppedEl.pos
    }
    return tempEl
}

export default draggingHandler
