import React from 'react'
import "./DragZone.css"

const DragZone = ({ children, styles }) => {
    return <>
        <div className="DragZone__container" style={styles}>
            {children}
        </div>

    </>
}

export default DragZone