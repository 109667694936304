import { Box, Button, Modal, TextField, Typography } from "@mui/material"
import axios from "axios"
import { useState } from "react"
import { useDispatch } from "react-redux"
import TorFilmService from "../../../services/TorFilmsService"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    rowGap: "20px"

}


const AddFilmModal = ({ isOpen, onClose, user, parent }) => {
    const [name, setName] = useState("")
    const [link, setLink] = useState("")

    const dispatch = useDispatch()

    const send = () => {
        dispatch(TorFilmService.addFilm(user, name, link, parent))
        setName("");
        setLink("")
        onClose()
    }

    return <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Добавить фильм
            </Typography>
            <TextField
                id="chapter-name"
                label="Название фильма"
                variant="standard"
                value={name}
                onChange={(e) => { setName(e.target.value) }} />
            <TextField
                id="chapter-link"
                label="Ссылка"
                variant="standard"
                value={link}
                onChange={(e) => { setLink(e.target.value) }} />
            <Button variant="contained" onClick={send}>Добавить</Button>
        </Box>
    </Modal>
}


export default AddFilmModal